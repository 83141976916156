<template>
  <div class="mTopicDetails">
    <!-- 方案内容 -->
    <div class="mTopicDetails_content">
      <!-- 方案内容项 -->
      <div
        class="mTopicDetails_contentItem"
        v-for="(item,index) in list"
        :key="index"
        @click="goHomeDetails(item.id)"
      >
        <!-- 内容项标题 -->
        <div class="mTopicDetails_contentItem_title">{{item.title}}</div>
        <!-- 内容项图片 -->
        <div class="mTopicDetails_contentItem_img">
          <img :src="item.cover" />
        </div>
        <!-- 内容项信息 -->
        <div class="mTopicDetails_contentItem_info">
          <div class="mTopicDetails_contentItem_infoRight">
            <div class="infoRight_avatar">
              <img :src="item.picture" />
            </div>
            <div class="infoRight_userName">{{item.username}}</div>
          </div>
          <div class="mTopicDetails_contentItem_infoLeft">
            <div>
              <div>
                <i class="el-icon-view"></i>
              </div>
              <div>{{item.visit_num}}</div>
            </div>
            <div>
              <div>
                <i class="iconfont icondianzan"></i>
              </div>
              <div>{{item.like_num}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getTopicDetails();
  },
  methods: {
    getTopicDetails() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/album/deails",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.topicDetails = res.data.data.album;
          this.list = res.data.data.list;
        }
      });
    },
    // 跳转详情页
    goHomeDetails(e) {
      this.$router.push({
        path: "/mHomeDetails",
        query: {
          id: e,
        },
      });
    },
  },
};
</script>

<style scoped>
/* 方案主体-方案内容 */
.mTopicDetails_content {
  margin: 3% auto;
}
.mTopicDetails_contentItem {
  width: 94%;
  margin: 3% auto;
  padding: 3%;
  background-color: #fff;
}
/* 方案内容-标题 */
.mTopicDetails_contentItem_title {
  font-size: 16px;
  font-weight: 600;
  margin: 2% 0;
}
/* 方案内容-图片 */
.mTopicDetails_contentItem_img {
  margin: 2% 0;
  width: 100%;
  border-radius: 3%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mTopicDetails_contentItem_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 方案内容-信息 */
.mTopicDetails_contentItem_info {
  margin: 3% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mTopicDetails_contentItem_infoRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
}
.mTopicDetails_contentItem_infoRight > div {
  margin: 0 2%;
}
.mTopicDetails_contentItem_infoLeft {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.mTopicDetails_contentItem_infoLeft > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 2%;
}
.mTopicDetails_contentItem_infoLeft > div > div {
  margin: 0 3px;
}
.mTopicDetails_contentItem_infoLeft > div i {
  font-size: 18px;
}
/* 方案内容-头像 */
.infoRight_avatar {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 100%;
  overflow: hidden;
}
.infoRight_avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>